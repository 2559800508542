import React from 'react';
import styled from 'styled-components';

import { theme } from '@hero-design/react';

import { mediaMaxQueries } from '@packages/hero-theme/utils';
import LinkButton from '@shared/LinkButton';

const PostJobButtonLink = styled(LinkButton)`
  margin: 0 ${theme.space.medium}px !important;
  color: ${theme.colors.text} !important;
  border-color: ${theme.colors.defaultDarkBackground} !important;

  ${mediaMaxQueries.lg} {
    display: none;
  }
`;

const PostJobButton = () => (
  <PostJobButtonLink
    text="POST A JOB"
    variant="basic-transparent"
    target="_blank"
    href={
      'https://employmenthero.com/create-account-employment-essentials/?utm_source=swag-jobs&utm_medium=swag&utm_campaign=swag-jobs'
    }
  />
);

export default PostJobButton;
