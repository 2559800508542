import React, { useCallback, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Session } from 'next-auth';
import { signOut } from 'next-auth/react';
import styled from 'styled-components';

import {
  Box,
  Button,
  Dropdown,
  Menu,
  theme,
  Typography,
} from '@hero-design/react';
import DefaultAvatar from '@public/assets/images/default-avatar.svg';

import Avatar from '@packages/avatar';
import generateGetURL from '@packages/eh-utils/urlGenerator';
import { TRACK_LOG_OUT, trackGTMEvent } from '@packages/google-tag-manager';
import { mediaMaxQueries } from '@packages/hero-theme/utils';
import { handleSignOutSideEffect } from '@shared/utils/handleAuthSideEffect';

import useFetchUserProfile from 'src/modules/User/hooks/useFetchUserProfile';

const StyledAvatar = styled(DefaultAvatar)`
  height: 40px;
  width: 40px;

  ${mediaMaxQueries.sm} {
    height: 25px;
    width: 25px;
  }
`;

const InfoWrapper = styled.div`
  margin-left: ${theme.space.small}px;

  ${mediaMaxQueries.md} {
    display: none;
  }
`;

const LinkWrapper = styled.a`
  text-decoration: none;
`;

type AuthStatusSignedInProps = {
  session: Session;
};

const AuthStatusSignedIn = ({ session }: AuthStatusSignedInProps) => {
  const { userProfileData } = useFetchUserProfile();
  const data = userProfileData?.data;

  const { user } = session;
  const router = useRouter();
  const [open, setOpen] = useState(false);

  const normalizeHref = useCallback(
    (href: string) =>
      generateGetURL(href, {
        mode: router?.query?.mode,
      }),
    [router?.query?.mode]
  );

  const dropdownContent = (
    <Menu>
      <Link
        href={normalizeHref(`/user/${user.friendlyId}`)}
        passHref
        legacyBehavior
      >
        <LinkWrapper>
          <Menu.Item text="Profile" onClick={() => setOpen(false)} />
        </LinkWrapper>
      </Link>

      <Link
        href={normalizeHref(`/user/profile_settings`)}
        passHref
        legacyBehavior
      >
        <LinkWrapper>
          <Menu.Item text="Profile settings" onClick={() => setOpen(false)} />
        </LinkWrapper>
      </Link>

      <Menu.Item
        text="Sign out"
        onClick={() => {
          handleSignOutSideEffect();
          trackGTMEvent(TRACK_LOG_OUT);
          signOut({ callbackUrl: '/' });
        }}
      />
    </Menu>
  );

  return (
    <Dropdown
      open={open}
      content={dropdownContent}
      onClose={() => setOpen(false)}
      target={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => setOpen(!open)}
        >
          {data?.avatar_url ? (
            <Box data-test-id="avatar">
              <Avatar
                fullName={`${data?.first_name} ${data?.last_name}`}
                avatarURL={data?.avatar_url}
                avatarStyles={{ width: 35, height: 35, border: 'none' }}
                crossOrigin
              />
            </Box>
          ) : (
            <StyledAvatar />
          )}

          <InfoWrapper>
            <Typography.Text intent="white">{user.name}</Typography.Text>
            <Typography.Text intent="subdued">{user.email}</Typography.Text>
          </InfoWrapper>

          <Button.Icon
            icon="carat-down"
            data-test-id="carat-down"
            sx={{ ml: 'small' }}
            intent="text"
          />
        </div>
      }
    />
  );
};

export default AuthStatusSignedIn;
