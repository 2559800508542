import type { SWRConfiguration } from 'swr';
import useSWR from 'swr';

import { useFetch } from '@packages/eh-utils/api';
import { getAtsServiceDirectApiHost } from '@packages/eh-utils/browserEnv';

import { DataWrapper, ErrorData } from '../../modules/CareersPage/types/index';

export type TPermissions = {
  access_saved_jobs: boolean;
  candidate_profile_ai_enabled: boolean;
  cv_parsing_enabled: boolean;
  setup_profile_wizard_enabled?: boolean;
  career_company_page_slice_2_enabled: boolean;
  introduction_video_enabled: boolean;
  access_skills: boolean;
  job_matching_enabled: boolean;
  public_profile_popup_enabled: boolean;
  shadow_job_matching_enabled: boolean;
  profile_geolocation_enabled: boolean;
  scmp_for_ats_web_enabled: boolean;
  show_unverified_user_restriction_banner?: boolean;
  show_unverified_locked_user_restriction_screen?: boolean;
  improve_candidate_data?: boolean;
  improve_candidate_data_slice_2?: boolean;
  mandatory_signup_enabled?: boolean;
  swag_profile_with_cv_parsing_populateable?: boolean;
  show_profile_stats: boolean;
  profile_address_autosuggestion_enabled?: boolean;
  smart_match_onboarding_v2_enabled?: boolean;
  saved_candidate_active_apply_enabled?: boolean;
  smart_match_collect_highlight_data?: boolean;
  education_refactoring_enabled?: boolean;
  smart_match_opensearch_job_recommendation_enabled?: boolean;
  revamp_default_company_page_enabled?: boolean;
  smart_match_education_highlights_enabled?: boolean;
  company_website_refactoring_enabled?: boolean;
  autocomplete_job_search_enabled?: boolean;
  smart_match_highlights_revamp_enabled?: boolean;
  smart_match_sms_notification_email_enabled?: boolean;
  direct_messaging_slice_2_enabled?: boolean;
  direct_onboarding_flow_v2?: boolean;
  swag_seo_strategy_2024_enabled?: boolean;
  claim_talent_new_banner_enabled?: boolean;
  claim_talent_salary_benchmark_enabled?: boolean;
  claim_talent_talent_pool_enabled?: boolean;
  notification_v1_enabled?: boolean;
  direct_messaging_read_status_enabled?: boolean;
  candidate_dm_enhancement_v1_enabled?: boolean;
  swag_ai_generated_summary_setting_enabled?: boolean;
  application_form_recaptcha_enabled?: boolean;
  smart_match_multisearch_recommended_jobs_enabled?: boolean;
  free_text_job_title_preference_enabled?: boolean;
  smart_match_candidate_interest_token_enabled?: boolean;
  automated_screening_questions_slice_1_enabled?: boolean;
  automated_screening_questions_slice_2_enabled?: boolean;
  smart_match_after_feedback_update_location_enabled?: boolean;
  automated_screening_questions_slice_3_enabled?: boolean;
  automated_screening_questions_slice_4_enabled?: boolean;
  swag_profile_parsing_logic_enhancement_enabled?: boolean;
  candidate_dashboard_enhancement_enabled?: boolean;
  job_search_v2_enabled?: boolean;
  user_screening_questions_improvement_enabled?: boolean;
  smart_match_potential_v3_enabled?: boolean;
  smart_match_new_apply_now_flow_enabled?: boolean;
  smart_match_ai_generated_prescreen_answers_enabled?: boolean;
  job_application_form_enhancements_enabled?: boolean;
  smart_match_direct_application_by_interested_link_enabled?: boolean;
};

const useFetchPermissions = (configs?: SWRConfiguration) => {
  const { fetcher } = useFetch<DataWrapper<TPermissions>, void, ErrorData>({
    method: 'GET',
    endpoint: `${getAtsServiceDirectApiHost()}/api/v1/permissions/webs`,
    withSWR: true,
  });

  const {
    data,
    mutate: fetchPermissions,
    error,
  } = useSWR<DataWrapper<TPermissions>, ErrorData>(
    { key: 'web_permission' },
    () => fetcher(),
    {
      ...configs,
      revalidateOnFocus: false,
    }
  );

  return {
    permissionsData: data,
    fetchPermissions,
    permissionsError: error,
    isFetchingPermissions: !data && !error,
  };
};

export default useFetchPermissions;
