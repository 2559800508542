import compact from 'lodash/fp/compact';
import upperCase from 'lodash/fp/upperCase';

import React, { HTMLAttributeAnchorTarget, useCallback, useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import styled from 'styled-components';

import {
  Badge,
  Box,
  Button,
  Dropdown,
  Icon,
  Menu,
  Typography,
} from '@hero-design/react';

import generateGetURL from '@packages/eh-utils/urlGenerator';
import { mediaMaxQueries, themeGet } from '@packages/hero-theme/utils';
import { Logo } from '@shared/Logo';
import useFetchPermissions from '@shared/hooks/useFetchPermissions';
import useWindowSize from '@shared/hooks/useWindowSize';

import { localeOptions } from '../utils/constants';

import AuthStatus from './AuthStatus';
import NotificationBell from './NotificationBell';
import PostJobButton from './PostJobButton';
import SideBar from './SideBar';

interface NavWrapperProps {
  centerItem?: boolean;
}

const LinkWrapper = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding: ${themeGet('space.small')}px;
`;

const NavWrapper = styled.div<NavWrapperProps>`
  background-color: ${({ theme }) => theme.colors.defaultLightBackground};
  padding: 0px 130px;
  display: flex;
  align-items: center;
  justify-content: ${props => (props.centerItem ? 'center' : 'flex-start')};
  height: 86px;

  ${mediaMaxQueries.xl} {
    padding: 0px 60px;
  }
  ${mediaMaxQueries.lg} {
    .navbar-item:not(:first-of-type) {
      display: none;
    }
  }
  ${mediaMaxQueries.md} {
    padding: 0px 50px;
  }
  ${mediaMaxQueries.sm} {
    padding: 5px 30px;
  }
`;

type navBarItem = {
  id: string;
  name: string;
  href: string;
  onClick?: () => void;
  target?: HTMLAttributeAnchorTarget;
  showIndicator?: boolean;
};
interface NavBavProp {
  navBarItems: navBarItem[];
}

const NavBar: React.FC<NavBavProp> = ({ navBarItems }) => {
  const { isXSmallScreen, isSmallScreen } = useWindowSize();
  const { permissionsData } = useFetchPermissions();

  const isShowNotificationBell =
    permissionsData?.data.direct_messaging_slice_2_enabled ||
    permissionsData?.data.notification_v1_enabled;

  const isDirectOnBoardingFlowV2 =
    permissionsData?.data.direct_onboarding_flow_v2;

  const { asPath, locale, query, pathname, push } = useRouter();
  const { status } = useSession();

  const [openDropdown, setOpenDropdown] = useState(false);
  const isAuthenticated = status === 'authenticated';

  const normalizeHref = useCallback(
    (href: string) =>
      generateGetURL(href, {
        mode: query?.mode,
      }),
    [query?.mode]
  );

  const authStatusNotSignedInNavBarItems = compact([
    !isAuthenticated && {
      id: 'log-in',
      name: 'Log In',
      href: '/sign_in',
    },
    !isAuthenticated && {
      id: 'sign-up',
      name: 'Sign Up',
      href: isDirectOnBoardingFlowV2 ? '/setup_profile' : '/sign_up',
    },
  ]);

  const postJobNavBarItem = compact([
    !isAuthenticated && {
      id: 'post-job',
      name: 'Post a Job',
      href: 'https://employmenthero.com/create-account-hiring-essentials/?utm_source=swag-jobs&utm_medium=swag&utm_campaign=swag-jobs',
    },
  ]);

  const sideBarItems = [
    ...authStatusNotSignedInNavBarItems,
    ...navBarItems,
    ...postJobNavBarItem,
  ];

  const changeActiveSite = (newLocale: string | undefined) => {
    if (newLocale) {
      push({ pathname, query }, asPath, { locale: newLocale, shallow: false });
      setOpenDropdown(false);
    }
  };

  const dropdownContent = (
    <Menu>
      {localeOptions.map(({ value, text }) => (
        <Menu.Item
          key={value}
          text={text}
          onClick={() => changeActiveSite(value)}
          active={locale === value}
        />
      ))}
    </Menu>
  );

  return (
    <>
      <NavWrapper>
        <SideBar navBarItems={sideBarItems} />
        <Link href={normalizeHref('/')} passHref legacyBehavior>
          <LinkWrapper>
            <Logo width={75} id="swag-logo" />
          </LinkWrapper>
        </Link>

        {navBarItems.map(
          ({ id, name, href, onClick, showIndicator, target = '_self' }) => (
            <Link href={normalizeHref(href)} passHref key={id} legacyBehavior>
              <LinkWrapper
                id={id}
                className="navbar-item"
                target={target}
                onClick={onClick}
              >
                {showIndicator ? (
                  <div data-test-id="red-dot-indicator-saved-candidate-wrapper">
                    <Badge.Status>
                      <Typography.Text
                        fontSize={14}
                        fontWeight="semi-bold"
                        sx={{ ml: 'xlarge' }}
                      >
                        {upperCase(name)}
                      </Typography.Text>
                    </Badge.Status>
                  </div>
                ) : (
                  <Typography.Text
                    fontSize={14}
                    fontWeight="semi-bold"
                    sx={{ ml: 'xlarge' }}
                  >
                    {upperCase(name)}
                  </Typography.Text>
                )}
              </LinkWrapper>
            </Link>
          )
        )}

        <Box
          sx={{ margin: '0 0 0 auto', display: 'flex', alignItems: 'center' }}
        >
          <Dropdown
            open={openDropdown}
            content={dropdownContent}
            onClose={() => setOpenDropdown(false)}
            data-test-id="locale-dropdown"
            target={
              <span
                onClick={() => setOpenDropdown(!openDropdown)}
                data-test-id="locale-select"
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    cursor: 'pointer',
                    mr: isSmallScreen && !isAuthenticated ? 0 : 'medium',
                    ml: 'xxlarge',
                  }}
                >
                  <Icon
                    icon="location"
                    sx={{ mr: 'small' }}
                    size="small"
                    data-test-id="locale-select-icon"
                  />
                  {!isXSmallScreen ? (
                    <>
                      <Typography.Text intent="subdued">
                        {localeOptions.find(({ value }) => value === locale)
                          ?.text || 'International'}
                      </Typography.Text>

                      <Button.Icon icon="carat-down" sx={{ ml: 'small' }} />
                    </>
                  ) : null}
                </Box>
              </span>
            }
          />
          {!isAuthenticated && <PostJobButton />}

          {isAuthenticated && isShowNotificationBell ? (
            <NotificationBell />
          ) : null}

          <AuthStatus />
        </Box>
      </NavWrapper>
    </>
  );
};

export default NavBar;
