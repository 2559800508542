import React from 'react';
import {
  ControllerFieldState,
  ControllerRenderProps,
  FieldPath,
  FieldValues,
} from 'react-hook-form';

import { Slider } from '@hero-design/react';

import Error from '../Error';
import FieldLabel from '../FieldLabel';
import InputContainer from '../InputContainer';
import { ExtraProps, LabelProps } from '../types';

interface SliderInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> {
  inputProps?: React.ComponentProps<typeof Slider>;
  labelProps?: LabelProps;
  extraProps?: ExtraProps & {
    alwaysShowTooltip?: boolean;
    customTooltipText?: (value: number) => string;
  };
  field: ControllerRenderProps<TFieldValues, TName>;
  fieldState: ControllerFieldState;
}

const SliderInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({
  field,
  fieldState,
  inputProps = {
    value: 0,
  },
  labelProps = {},
  extraProps = {},
}: SliderInputProps<TFieldValues, TName>) => {
  const { disabled, min, max, step = 1, value, style } = inputProps;
  const {
    text: labelText,
    subText: labelSubText,
    required,
    tooltip,
    inline = false,
  } = labelProps;
  const { error } = fieldState;
  const { onChange, name } = field;
  const {
    error: extraError,
    'data-test-id': dataTestId,
    alwaysShowTooltip,
    customTooltipText: externalCustomTooltipText = undefined,
  } = extraProps;
  const hasError = error != null || extraError != null;

  const id = `hero-theme-slider-input__${name}`;

  return (
    <InputContainer data-test-id={dataTestId} inline={inline}>
      <FieldLabel
        required={required}
        text={labelText}
        subText={labelSubText}
        hasError={hasError}
        disabled={disabled}
        tooltip={tooltip}
        clickable
        htmlFor={id}
        input={
          <Slider
            id={id}
            value={value}
            onChange={onChange}
            disabled={disabled}
            alwaysShowTooltip={alwaysShowTooltip}
            customTooltipText={externalCustomTooltipText?.(value)}
            min={min}
            max={max}
            step={step}
            style={style}
          />
        }
      />

      {hasError === true && (
        <Error text={(error?.message as string) || (extraError as string)} />
      )}
    </InputContainer>
  );
};

export default SliderInput;
