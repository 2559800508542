import React from 'react';
import { useSession } from 'next-auth/react';

import AuthStatusNotSignedIn from './AuthStatusNotSignedIn';
import AuthStatusSignedIn from './AuthStatusSignedIn';

const AuthStatus = () => {
  const { data: session, status } = useSession();
  const user = session?.user;

  if (status === 'loading') {
    return null;
  }

  return (
    <>
      {!user ? (
        <AuthStatusNotSignedIn />
      ) : (
        <AuthStatusSignedIn session={session} />
      )}
    </>
  );
};

export default AuthStatus;
