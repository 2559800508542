export const getAtsServiceApiHost = () =>
  process.env.NEXT_PUBLIC_REACT_APP_ATS_SERVICE_API_HOST;

export const getMixPanelTokenId = () =>
  process.env.NEXT_PUBLIC_REACT_APP_MIXPANEL_TOKEN;

export const getShowWorkEligibility = () =>
  process.env.NEXT_PUBLIC_REACT_APP_SHOW_WORK_ELIGIBILITY;

export const getCurrentApiHost = () => process.env.NEXTAUTH_URL;

export const getCareerPageHost = () => process.env.CAREER_PAGE_HOST;
